import React from 'react'
import Layout from '../../components/layout'
import PageHeader from '../../components/header/page-header'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../../components/seo'
import SpyMenu from '../../components/menus/spyMenu'
import BackgroundImage from 'gatsby-background-image'

import { Section2IconList } from '../../components/Section2IconList'
import PrimaryButton from '../../components/buttons/primary_button'

import Image from 'gatsby-image'
import WhyChooseUsSection from '../../components/whyChooseUsSection'
import ServiceIconBoxes from '../../components/ServiceIconBoxes'
import CustomersSections from '../../components/customersSection'
import Testimonial from '../../components/Testimonial'
import ClientCarousel from '../../components/ClientCarousel'

const CyberSecurity = () => {
  const data = useStaticQuery(graphql`
    query CyberSecurity {
      ...IndustryWeServeFragment
      bgImage: file(relativePath: { eq: "services/cyber-security-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ipad: file(relativePath: { eq: "home/ipad.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamImage: file(
        relativePath: {
          eq: "services/business-concept-development-teamImage.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testomonial1: file(relativePath: { eq: "testomonial/2.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testomonial2: file(relativePath: { eq: "testomonial/1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonials: allTestimonialsJson {
        nodes {
          description
          className
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          role
        }
      }
    }
  `)

  const testimonials = data.testimonials.nodes

  const it_support_routes = [
    {
      name: 'Benefits',
      route: 'benefits',
      offset: -10
    },
    {
      name: 'Solutions',
      route: 'solutions',
      offset: 50
    },
    {
      name: 'Case Studies',
      route: 'case-studies',
      offset: -55
    },
    {
      name: 'Customers',
      route: 'customers',
      offset: -60
    },
    {
      name: 'Why Choose Us',
      route: 'why-choose-us',
      offset: -50
    },
    {
      name: 'Other Services',
      route: 'other-services',
      offset: -10
    }
  ]

  const teamImage = data.teamImage.childImageSharp.fluid
  // const backgroundImage = data.teamImage.childImageSharp.fluid
  const ipadImage = data.ipad.childImageSharp.fluid
  const testomonials = [
    {
      name:
        'Eduplus - Mr.Sujith Liyanage -(Director/Teacher) -  Viduna Institute',
      image: data.testomonial1.childImageSharp.fluid,
      description:
        'Eduplus” is the great product which I found to manage my institute without any hassle from any involved parties such as students, parents & institute staff. The Team Explorelogy worked with us in a very passionate and energetic way with a positive approach that always increased my satisfaction level on their product.',
      className: 'testomonial-box custom-border'
    },
    {
      name: 'M.K. Daya Priyantha (Director/CEO) - Invos Global (PVT) LTD ',
      image: data.testomonial2.childImageSharp.fluid,
      description:
        'They have the power of Conceptualization of our business idea and the concept from the Digital perspective which helps for the betterment of the company, In simple words they think bigger than we do as business owners. One of my best decisions which I have taken and never regret is selecting “Explorelogy” as an IT partner.',
      className: 'testomonial-box-2'
    }
  ]

  const pageTitle = 'Cyber Secuirty'
  const pageDescripton =
    'We make defending your IT resources our number one priority'
  return (
    <Layout>
      <SEO title={pageTitle} description={pageDescripton} />
      <PageHeader
        className="padding-180"
        title={pageTitle}
        subtitle={pageDescripton}
        background={data.bgImage.childImageSharp.fluid}
        thisPage={pageTitle}
        previousPage="IT Services"
        previousPageLink="/services/"
      />
      <SpyMenu routes={it_support_routes} />
      <div id="benefits" className="container-fluid pt-3">
        <div className="col-md-6 mx-auto">
          <h2 style={{ textAlign: 'center', textTransform: 'uppercase' }}>
            Manage your comprehensive security risk
          </h2>
          {/* <div style={{ height: 20 }}></div> */}
          <div>
            <p style={{ textAlign: 'center' }}>
              Explorelogy provides businesses with holistic IT services
              including improving company infrastructure and addressing safety
              concerns related to cyber attacks and software malfunctions. We
              provide a range of solutions including firewall services from
              inception throughout implementation.
              <br />
              <br />
              As businesses grow, industries require certifications of
              maintained security standards. By handing your security issues to
              trusted hands, you are able to receive the necessary
              certifications of standard and better your business prospects. We
              help to identify security issues, protect infrastructure, ensure
              applications are secure and address vulnerabilities.
            </p>
          </div>
          <div style={{ height: 60 }}></div>
        </div>
        <div className="container">
          <div className="row">
            {benefits_data.map((item, index) => {
              return (
                <BenefitBox
                  key={index}
                  className={item.className}
                  title={item.title}
                  description={item.description}
                />
              )
            })}
          </div>
        </div>
        <div style={{ height: 200 }}></div>
      </div>
      <div className="container-fluid bg-white">
        <div className="row ">
          <div className="col-8 col-md-4 mx-auto">
            <div
              style={{ marginTop: -120 }}
              className=" text-center position-relative"
            >
              <div>
                <Image className="img-fluid" fluid={ipadImage}></Image>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mx-auto pt-5">
          <h2
            className="what-service"
            style={{ textAlign: 'center', textTransform: 'uppercase' }}
          >
            What is Cyber Security?
          </h2>
          {/* <div style={{ height: 20 }}></div> */}
          <div>
            <p style={{ textAlign: 'center' }}>
              Transform your business from the culture of fear and uncertainty
              to the business of trust by Manage Critical infrastructures,
              Applications, Networks,Clouds and internet security are the
              components of cyber security. It is a healing process or
              protecting partnering to the broken links in cyber-security which
              create cyber-resilient.
            </p>
          </div>
          <div style={{ height: 60 }}></div>
        </div>
        <div className="col-md-10 mt-0 mb-0 mb-0 mx-auto">
          <div className="row">
            <div className="col-md-7">
              <BackgroundImage
                Tag="div"
                fluid={teamImage}
                className="section-2-team"
              ></BackgroundImage>
            </div>
            <div className="col-md-5">
              <div className="container-box light-blue">
                <h2
                  className="secret-behind"
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: '100',
                    marginTop: 20
                  }}
                >
                  Secret Behind Us - Our force
                </h2>
                <div style={{ height: 10 }}></div>
                <ul className="section-2-iconlist">
                  <Section2IconList>
                    Highest rate of retantion 100% for all staff
                  </Section2IconList>
                  <Section2IconList>
                    Our team passionate to welcome challenges
                  </Section2IconList>
                  <Section2IconList>
                    Long term relationships lead to strong business strategies.
                  </Section2IconList>
                  <Section2IconList>
                    Our clients are totally secured by our expert teams.
                  </Section2IconList>
                </ul>
                <div style={{ height: 20 }}></div>
                <PrimaryButton
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '90%'
                  }}
                  title="Find Out More"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="solutions" className="container-fluid bg-white py-5"></div>
      <div id="case-studies" className="container"></div>
      <CustomersSections>
        <div className="container">
          <ClientCarousel clientImages={data.clientImages} />
        </div>
      </CustomersSections>
      {/* <Testimonial testomonial={testimonials} /> */}

      <WhyChooseUsSection />
      <div id="other-services" className="container-fluid">
        <div className="row mx-0">
          <div className="col-11 col-md-10 mx-auto">
            <div className="column-inner">
              <div style={{ width: '100%' }} className="separator">
                <span className="sep_holder">
                  <span className="sep_line"></span>
                </span>
                <p
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 0,
                    fontSize: '1rem',
                    fontWeight: '100',
                    textAlign: 'center'
                  }}
                >
                  Diversified solutions to suit your requirements
                </p>
                <span className="sep_holder">
                  <span className="sep_line"></span>
                </span>
              </div>
              <div style={{ height: 40 }}></div>
              {/* service icon boxes */}
              <ServiceIconBoxes />
              {/* service icon boxes */}
              <div style={{ height: 20 }}></div>
              <div className="text-column pb-5">
                <p style={{ textAlign: 'center' }}>
                  IT services built specifically for your business. &nbsp;
                  <a className="link" href="/#">
                    Find your solution
                  </a>
                </p>
              </div>
              {/* <div style={{ height: 230 }}></div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const BenefitBox = ({ className, title, description }) => {
  return (
    <div className="col-md-4">
      <div className={`benefit-box ${className}`}>
        <h4 style={{ marginTop: 0, marginBottom: 15, color: '#fff' }}>
          <span>{title}</span>
        </h4>
        <p style={{ color: '#fff' }}>{description}</p>
      </div>
    </div>
  )
}

const benefits_data = [
  {
    title: 'Cost control',
    description:
      'Taking care of security breaches and cyber attacks are costly and demand immediate attention. With our cyber security services you pay a small price for guaranteed safety without the fear of attacks causing financial loss. ',
    className: 'lightblue'
  },
  {
    title: 'Business benefits',
    description:
      'Having your cyber security in safe hands enables you to guarantee the best of your business/ product to your customers as well as receive all necessary certifications guaranteeing your safety standard.',
    className: 'blue'
  },
  {
    title: 'Suited for you',
    description:
      'If you are concerned about the cyber security of your companies, bank, or financial institute, reach out to us and we will handle the rest',
    className: 'lightorange'
  }
]

export default CyberSecurity
